import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem } from '@mui/material';

const SendMessage = () => {
    const [open, setOpen] = useState(false);
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');

    const textTemplates = {
        text: "Sizga Millat Umidi Universtiteti mas'ul xodimi aloqaga chiqdi. Lekin siz bilan bog'lanish imkoni bo'lmadi!\n" +
            "Qabul jarayoni yakunlanishiga sanoqli kunlar qoldi. Shuni inobatga olgan holda iltimos biz bilan bog'laning.\n" +
            "Telefon: +998712000306\n" +
            "Operator raqami: (Operator raqamingizni kiriting)",
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = () => {
        const data = {
            phone: phone,
            message: message
        };
        const token = localStorage.getItem('userToken');
        fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/users/send/sms`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to send message');
                }
            })
            .then(data => {
                alert('Message sent successfully!');
            })
            .catch((error) => {
                alert("Failed to send message, please try again");
            });

        handleClose();
    };

    const handleTemplateChange = (event) => {
        const templateKey = event.target.value;
        setSelectedTemplate(templateKey);
        setMessage(textTemplates[templateKey]);
    };

    return (
        <div>
            <Button
                type="button"
                style={{border: '1px solid white', color: 'white', marginRight: '15px'}}
                onClick={handleClickOpen}
            >
                Send SMS
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                PaperProps={{
                    style: {
                        minWidth: '600px',
                        width: '600px',
                    }
                }}
            >
                <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
                <DialogContent style={{ width: '600px' }}>
                    <DialogContentText>
                        Enter the phone number and the message below.
                    </DialogContentText>
                    <Select
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ marginBottom: '20px' }}
                    >
                        <MenuItem value="">
                            <em>Choose a template</em>
                        </MenuItem>
                        {Object.keys(textTemplates).map((key) => (
                            <MenuItem key={key} value={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="phone"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="message"
                        label="Message"
                        type="text"
                        fullWidth
                        multiline
                        rows={8}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleSend} variant="contained" color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SendMessage;
