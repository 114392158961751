import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, TablePagination } from '@material-ui/core';

const Statistics = () => {
    const [statistics, setStatistics] = useState(null);
    const [countByRefID, setCountByRefID] = useState([]);
    const [referralStatistics, setReferralStatistics] = useState([]);
    const [visibleReferralStats, setVisibleReferralStats] = useState(5);
    const [visibleRefIDRows, setVisibleRefIDRows] = useState(5);
    const [paymentView, setPaymentView] = useState('All');
    const [contractView, setContractView] = useState('All');
    const [statusView, setStatusView] = useState('All');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const today = new Date().toISOString().split('T')[0];
    const [countAllByPeriod, setCountAllByPeriod] = useState(0);
    const [telegramBotData, setTelegramBotData] = useState([]);
    const [visibleRows, setVisibleRows] = useState(5);

    useEffect(() => {
        fetchStatistics();
    }, [fromDate, toDate]);

    useEffect(() => {
        fetchTelegramBot();
    }, []);

    const fetchStatistics = async () => {
        const token = localStorage.getItem('userToken');
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/statistics?from=${fromDate}&to=${toDate}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error(response.status);
            }

            const data = await response.json();
            setStatistics(data.data);
            setCountAllByPeriod(data.data.countAllByPeriod);
            setReferralStatistics(data.data.countApplicantsByTargetRefId);
            setCountByRefID(data.data.countByRefID.filter(item => item.refID !== null));
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };

    const fetchTelegramBot = async () => {
        const username = 'univ';
        const password = 'F4#v2sD8!r7P9qKz';
        const encodedCredentials = btoa(`${username}:${password}`);

        try {
            const response = await fetch(`https://api-muniv.edu-action.uk/api/not-applied-users?from=2024-03-15&to=${today}`, {
                headers: {
                    'Authorization': `Basic ${encodedCredentials}`
                },
            });
            if (!response.ok) {
                throw new Error(response.status);
            }
            const data = await response.json();
            setTelegramBotData(data[0].lookupSources);
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const handleReset = () => {
        setFromDate('');
        setToDate('');
        fetchStatistics();
    };

    const showMoreReferralStats = () => {
        setVisibleReferralStats(referralStatistics.length);
    };

    const showLessReferralStats = () => {
        setVisibleReferralStats(5);
    };

    const showMoreRefIDRows = () => {
        setVisibleRefIDRows(countByRefID.length);
    };

    const showLessRefIDRows = () => {
        setVisibleRefIDRows(5);
    };

    const totalRowStyle = {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
        color: '#333'
    };

    const renderTelegramBotTable = () => {
        return (
            <TableContainer component={Paper} style={{ maxWidth: 800, margin: '20px auto' }}>
                <h4 style={{ textAlign: "center" }}>Telegram Bot Statistics</h4>
                <Table aria-label="Telegram Bot Statistics">
                    <TableHead>
                        <TableRow>
                            <TableCell>Source ID</TableCell>
                            <TableCell align="right">Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {telegramBotData.slice(0, visibleRows).map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item._id}</TableCell>
                                <TableCell align="right">{item.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    {visibleRows < telegramBotData.length && (
                        <Button onClick={() => setVisibleRows(telegramBotData.length)} style={{ marginRight: '10px' }}>
                            Show More
                        </Button>
                    )}
                    {visibleRows > 5 && (
                        <Button onClick={() => setVisibleRows(5)}>
                            Show Less
                        </Button>
                    )}
                </div>
            </TableContainer>
        );
    };

    const renderCountByProgramTable = (data, title) => {
        const sumInit = {
            approved: 0,
            rejected: 0,
            submitted: 0,
            pending: 0,
            total: 0
        };

        const totals = data.reduce((acc, item) => {
            acc.approved += Number(item.approved) || 0;
            acc.rejected += Number(item.rejected) || 0;
            acc.submitted += Number(item.submitted) || 0;
            acc.pending += Number(item.pending) || 0;
            acc.total += Number(item.total) || 0;
            return acc;
        }, sumInit);

        return (
            <TableContainer component={Paper} style={{ maxWidth: 800, margin: '5px auto' }}>
                <h4 style={{ textAlign: "center" }}>{title}</h4>
                <Table aria-label={title}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Faculty</TableCell>
                            <TableCell align="right">Pending</TableCell>
                            <TableCell align="right">Submitted</TableCell>
                            <TableCell align="right">Rejected</TableCell>
                            <TableCell align="right">Approved</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.faculty}</TableCell>
                                <TableCell align="right">{item.pending}</TableCell>
                                <TableCell align="right">{item.submitted}</TableCell>
                                <TableCell align="right">{item.rejected}</TableCell>
                                <TableCell align="right">{item.approved}</TableCell>
                                <TableCell align="right">{item.total}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={totalRowStyle}>
                            <TableCell align="left"><b>Total</b></TableCell>
                            <TableCell align="right"><b>{totals.pending}</b></TableCell>
                            <TableCell align="right"><b>{totals.submitted}</b></TableCell>
                            <TableCell align="right"><b>{totals.rejected}</b></TableCell>
                            <TableCell align="right"><b>{totals.approved}</b></TableCell>
                            <TableCell align="right"><b>{totals.total}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderPaymentTable = (data, title) => {
        const sumInit = {
            paid1MlnUZS: 0,
            paidHalf: 0,
            paidFull: 0,
            paidPartly: 0,
            total: 0
        };

        const totals = data.reduce((acc, item) => {
            acc.paid1MlnUZS += Number(item.paid1MlnUZS) || 0;
            acc.paidHalf += Number(item.paidHalf) || 0;
            acc.paidFull += Number(item.paidFull) || 0;
            acc.paidPartly += Number(item.paidPartly) || 0;
            acc.total += Number(item.total) || 0;
            return acc;
        }, sumInit);

        return (
            <TableContainer component={Paper} style={{ maxWidth: 800, margin: '5px auto' }}>
                <h4 style={{ textAlign: "center" }}>{title}</h4>
                <Table aria-label={title}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Faculty</TableCell>
                            <TableCell align="right">Paid 1Mln UZS</TableCell>
                            <TableCell align="right">Paid Half</TableCell>
                            <TableCell align="right">Paid Full</TableCell>
                            <TableCell align="right">Paid Partly</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.faculty}</TableCell>
                                <TableCell align="right">{item.paid1MlnUZS || 0}</TableCell>
                                <TableCell align="right">{item.paidHalf || 0}</TableCell>
                                <TableCell align="right">{item.paidFull || 0}</TableCell>
                                <TableCell align="right">{item.paidPartly || 0}</TableCell>
                                <TableCell align="right">{item.total}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={totalRowStyle}>
                            <TableCell align="left"><b>Total</b></TableCell>
                            <TableCell align="right"><b>{totals.paid1MlnUZS}</b></TableCell>
                            <TableCell align="right"><b>{totals.paidHalf}</b></TableCell>
                            <TableCell align="right"><b>{totals.paidFull}</b></TableCell>
                            <TableCell align="right"><b>{totals.paidPartly}</b></TableCell>
                            <TableCell align="right"><b>{totals.total}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderContractTable = (data, title) => {
        const sumInit = {
            contractSent: 0,
            contractViewed: 0,
            contractSigned: 0,
            totalContractIssued: 0
        };

        const totals = data.reduce((acc, item) => {
            acc.contractSent += Number(item.contractSent) || 0;
            acc.contractViewed += Number(item.contractViewed) || 0;
            acc.contractSigned += Number(item.contractSigned) || 0;
            acc.totalContractIssued += Number(item.totalContractIssued) || 0;
            return acc;
        }, sumInit);

        return (
            <TableContainer component={Paper} style={{ maxWidth: 800, margin: '5px auto' }}>
                <h4 style={{ textAlign: "center" }}>{title}</h4>
                <Table aria-label={title}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Faculty</TableCell>
                            <TableCell align="right">Contract Sent (Not Signed)</TableCell>
                            <TableCell align="right">Contract Viewed</TableCell>
                            <TableCell align="right">Contract Signed</TableCell>
                            <TableCell align="right">Total Contract Issued</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.faculty}</TableCell>
                                <TableCell align="right">{item.contractSent || '0'}</TableCell>
                                <TableCell align="right">{item.contractViewed || '0'}</TableCell>
                                <TableCell align="right">{item.contractSigned || '0'}</TableCell>
                                <TableCell align="right">{item.totalContractIssued || '0'}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={totalRowStyle}>
                            <TableCell align="left"><b>Total</b></TableCell>
                            <TableCell align="right"><b>{totals.contractSent}</b></TableCell>
                            <TableCell align="right"><b>{totals.contractViewed}</b></TableCell>
                            <TableCell align="right"><b>{totals.contractSigned}</b></TableCell>
                            <TableCell align="right"><b>{totals.totalContractIssued}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderCountByProgramsTable = () => {
        const totalSums = statistics.countByStudyTypeAndProgram.reduce((acc, item) => {
            acc.pending += Number(item.pending) || 0;
            acc.submitted += Number(item.submitted) || 0;
            acc.rejected += Number(item.rejected) || 0;
            acc.approved += Number(item.approved) || 0;
            acc.total += Number(item.total) || 0;
            return acc;
        }, { pending: 0, submitted: 0, rejected: 0, approved: 0, total: 0 });

        return (
            <TableContainer component={Paper} style={{ maxWidth: 800, margin: '5px auto' }}>
                <h4 style={{ textAlign: "center" }}>Count by Program and Application Status</h4>
                <Table aria-label="Count by Program and Application Status">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Faculty</TableCell>
                            <TableCell align="left">Pending</TableCell>
                            <TableCell align="left">Submitted</TableCell>
                            <TableCell align="left">Rejected</TableCell>
                            <TableCell align="left">Approved</TableCell>
                            <TableCell align="left">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statistics.countByStudyTypeAndProgram.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align="left">{item.code}</TableCell>
                                <TableCell align="left">{item.pending}</TableCell>
                                <TableCell align="left">{item.submitted}</TableCell>
                                <TableCell align="left">{item.rejected}</TableCell>
                                <TableCell align="left">{item.approved}</TableCell>
                                <TableCell align="left">{item.total}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={totalRowStyle}>
                            <TableCell align="left"><b>Total</b></TableCell>
                            <TableCell align="left"><b>{totalSums.pending}</b></TableCell>
                            <TableCell align="left"><b>{totalSums.submitted}</b></TableCell>
                            <TableCell align="left"><b>{totalSums.rejected}</b></TableCell>
                            <TableCell align="left"><b>{totalSums.approved}</b></TableCell>
                            <TableCell align="left"><b>{totalSums.total}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderCountByStatusTable = () => {
        const totalCount = statistics.countByStatus.reduce((acc, status) => acc + Number(status.count) || 0, 0);

        return (
            <TableContainer component={Paper} style={{ maxWidth: 800, margin: '5px auto' }}>
                <h4 style={{ textAlign: "center" }}>Count by Status</h4>
                <Table aria-label="Count by Status">
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {statistics.countByStatus.map((status, index) => (
                            <TableRow key={index}>
                                <TableCell>{status.applicationStatus}</TableCell>
                                <TableCell align="right">{status.count}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={totalRowStyle}>
                            <TableCell align="left"><b>Total</b></TableCell>
                            <TableCell align="right"><b>{totalCount}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderReferralStatisticsTable = () => {
        const sumInit = {
            websiteVisitCount: 0,
            admissionVisitCount: 0,
            approved: 0,
            submitted: 0,
            pending: 0,
            rejected: 0,
            total: 0
        };

        const totals = referralStatistics.reduce((acc, item) => {
            acc.websiteVisitCount += Number(item.websiteVisitCount) || 0;
            acc.admissionVisitCount += Number(item.admissionVisitCount) || 0;
            acc.approved += Number(item.approved) || 0;
            acc.submitted += Number(item.submitted) || 0;
            acc.pending += Number(item.pending) || 0;
            acc.rejected += Number(item.rejected) || 0;
            acc.total += Number(item.total) || 0;
            return acc;
        }, sumInit);

        const sortedReferralStatistics = referralStatistics.slice().sort((a, b) => {
            const maxA = Math.max(Number(a.websiteVisitCount) || 0, Number(a.admissionVisitCount) || 0);
            const maxB = Math.max(Number(b.websiteVisitCount) || 0, Number(b.admissionVisitCount) || 0);
            return maxB - maxA;
        });

        return (
            <TableContainer component={Paper} style={{ maxWidth: 900, margin: '20px auto' }}>
                <h4 style={{ textAlign: "center" }}>Referral Statistics</h4>
                <Table aria-label="Referral Statistics">
                    <TableHead>
                        <TableRow>
                            <TableCell>TargetRef ID</TableCell>
                            <TableCell align="right">Website Visits</TableCell>
                            <TableCell align="right">Admission Visits</TableCell>
                            <TableCell align="right">Pending</TableCell>
                            <TableCell align="right">Submitted</TableCell>
                            <TableCell align="right">Rejected</TableCell>
                            <TableCell align="right">Approved</TableCell>
                            <TableCell align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedReferralStatistics.slice(0, visibleReferralStats).map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.targetRefId}</TableCell>
                                <TableCell align="right">{item.websiteVisitCount || '0'}</TableCell>
                                <TableCell align="right">{item.admissionVisitCount || '0'}</TableCell>
                                <TableCell align="right">{item.pending || '0'}</TableCell>
                                <TableCell align="right">{item.submitted || '0'}</TableCell>
                                <TableCell align="right">{item.rejected || '0'}</TableCell>
                                <TableCell align="right">{item.approved || '0'}</TableCell>
                                <TableCell align="right">{item.total || '0'}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={totalRowStyle}>
                            <TableCell align="left"><b>Total</b></TableCell>
                            <TableCell align="right"><b>{totals.websiteVisitCount}</b></TableCell>
                            <TableCell align="right"><b>{totals.admissionVisitCount}</b></TableCell>
                            <TableCell align="right"><b>{totals.pending}</b></TableCell>
                            <TableCell align="right"><b>{totals.submitted}</b></TableCell>
                            <TableCell align="right"><b>{totals.rejected}</b></TableCell>
                            <TableCell align="right"><b>{totals.approved}</b></TableCell>
                            <TableCell align="right"><b>{totals.total}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    {visibleReferralStats > 5 && (
                        <Button onClick={showLessReferralStats} style={{ marginRight: '10px' }}>
                            Show Less
                        </Button>
                    )}
                    {visibleReferralStats < referralStatistics.length && (
                        <Button onClick={showMoreReferralStats}>
                            Show More
                        </Button>
                    )}
                </div>
            </TableContainer>
        );
    };

    const renderRefIDTable = () => (
        <TableContainer component={Paper} style={{ maxWidth: 800, margin: '5px auto' }}>
            <h4 style={{ textAlign: "center" }}>Count by Reference ID</h4>
            <Table aria-label="Count by Reference ID">
                <TableHead>
                    <TableRow>
                        <TableCell>Reference ID</TableCell>
                        <TableCell align="right">Submitted</TableCell>
                        <TableCell align="right">Pending</TableCell>
                        <TableCell align="right">Approved</TableCell>
                        <TableCell align="right">Rejected</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {countByRefID.slice(0, visibleRefIDRows).map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.refID}</TableCell>
                            <TableCell align="right">{item.submitted}</TableCell>
                            <TableCell align="right">{item.pending}</TableCell>
                            <TableCell align="right">{item.approved}</TableCell>
                            <TableCell align="right">{item.rejected}</TableCell>
                            <TableCell align="right">{item.total}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                {visibleRefIDRows > 5 && (
                    <Button onClick={showLessRefIDRows} style={{ marginRight: '10px' }}>
                        Show Less
                    </Button>
                )}
                {visibleRefIDRows < countByRefID.length && (
                    <Button onClick={showMoreRefIDRows}>
                        Show More
                    </Button>
                )}
            </div>
        </TableContainer>
    );

    return (
        <div>
            <h3 style={{textAlign: "center", marginTop: "10px"}}>All Applicants: {countAllByPeriod}</h3>
            <div style={{display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px'}}>
                <TextField
                    label="From"
                    type="date"
                    value={fromDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => setFromDate(e.target.value)}
                />
                <TextField
                    label="To"
                    type="date"
                    value={toDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => setToDate(e.target.value)}
                />
                <Button variant="contained" onClick={handleReset}
                        style={{display: "inline-block", padding: "5px 30px"}}>Reset</Button>
            </div>
            {statistics && (
                <>
                    <div className="container-stat">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div
                                    style={{display: "flex", justifyContent: "center", margin: "20px", width: "700px"}}>
                                    <Button onClick={() => setStatusView('All')}
                                            variant={statusView === 'All' ? "contained" : "outlined"}>
                                        All
                                    </Button>
                                    <Button onClick={() => setStatusView('FullTime')}
                                            variant={statusView === 'FullTime' ? "contained" : "outlined"}
                                            style={{margin: "0 10px"}}>
                                        Full Time
                                    </Button>
                                    <Button onClick={() => setStatusView('PartTime')}
                                            variant={statusView === 'PartTime' ? "contained" : "outlined"}>
                                        Part Time
                                    </Button>
                                </div>
                                {statusView === 'All' && renderCountByProgramsTable(statistics.countByStudyTypeAndProgram, 'Count by Program and Application Status')}
                                {statusView === 'FullTime' && renderCountByProgramTable(statistics.countByProgramAndApplicationStatusAndStudyTypeFullTime, 'Count by Program and Application Status (Full Time)')}
                                {statusView === 'PartTime' && renderCountByProgramTable(statistics.countByProgramAndApplicationStatusAndStudyTypePartTime, 'Count by Program and Application Status (Part Time)')}
                            </div>
                            <div>
                                <div
                                    style={{display: "flex", justifyContent: "center", margin: "20px", width: "700px"}}>
                                    <Button onClick={() => setPaymentView('All')}
                                            variant={paymentView === 'All' ? "contained" : "outlined"}>
                                        All
                                    </Button>
                                    <Button onClick={() => setPaymentView('FullTime')}
                                            variant={paymentView === 'FullTime' ? "contained" : "outlined"}
                                            style={{margin: "0 10px"}}>
                                        Full Time
                                    </Button>
                                    <Button onClick={() => setPaymentView('PartTime')}
                                            variant={paymentView === 'PartTime' ? "contained" : "outlined"}>
                                        Part Time
                                    </Button>
                                </div>
                                {paymentView === 'All' && renderPaymentTable(statistics.countByPaymentAmountAndFaculty, 'Count by Payment Amount and Faculty')}
                                {paymentView === 'FullTime' && renderPaymentTable(statistics.countByPaymentAmountAndFacultyFullTime, 'Count by Payment Amount and Faculty (Full Time)')}
                                {paymentView === 'PartTime' && renderPaymentTable(statistics.countByPaymentAmountAndFacultyPartTime, 'Count by Payment Amount and Faculty (Part Time)')}
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                <div
                                    style={{display: "flex", justifyContent: "center", margin: "20px", width: "700px"}}>
                                    <Button onClick={() => setContractView('All')}
                                            variant={contractView === 'All' ? "contained" : "outlined"}>
                                        All
                                    </Button>
                                    <Button onClick={() => setContractView('FullTime')}
                                            variant={contractView === 'FullTime' ? "contained" : "outlined"}
                                            style={{margin: "0 10px"}}>
                                        Full Time
                                    </Button>
                                    <Button onClick={() => setContractView('PartTime')}
                                            variant={contractView === 'PartTime' ? "contained" : "outlined"}>
                                        Part Time
                                    </Button>
                                </div>
                                {contractView === 'All' && renderContractTable(statistics.countByContractIssuedAndFaculty, 'Count by Contract Issued and Faculty')}
                                {contractView === 'FullTime' && renderContractTable(statistics.countByContractIssuedAndFacultyFullTime, 'Count by Contract Issued and Faculty (Full Time)')}
                                {contractView === 'PartTime' && renderContractTable(statistics.countByContractIssuedAndFacultyPartTime, 'Count by Contract Issued and Faculty (Part Time)')}
                            </div>
                            <div style={{width: "700px", marginTop: "70px"}}>
                                {renderCountByStatusTable()}
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <TableContainer component={Paper} style={{width: 700, marginTop: "20px"}}>
                                <h4 style={{textAlign: "center"}}>Count by Registration Platform</h4>
                                <Table aria-label="Count by Registration Platform">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Registration Platform</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {statistics.countByRegistrationPlatform
                                            .filter(platform => platform && platform.registrationPlatform !== null && platform.count !== null)
                                            .map((platform, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{platform.registrationPlatform}</TableCell>
                                                    <TableCell align="right">{platform.count}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} style={{maxWidth: 700, marginTop: "20px"}}>
                                <h4 style={{textAlign: "center"}}>Count by Study Type</h4>
                                <Table aria-label="Count by Study Type">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Study Type</TableCell>
                                            <TableCell align="right">Count</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {statistics.countByStudyType.map((type, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{type.studyType || "Not Selected Faculty"}</TableCell>
                                                <TableCell align="right">{type.count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        {renderReferralStatisticsTable()}
                        {telegramBotData.length > 0 && renderTelegramBotTable()}
                        {renderRefIDTable()}
                    </div>
                </>
            )}
        </div>
    );
};

export default Statistics;