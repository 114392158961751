import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CallCenterDataTable from './CallCenterDataTable';
import LinkModal from './LinkModal';
import SendMessage from './sendMessage';

const CallOperators = () => {
    const [passportNumber, setPassportNumber] = useState("");
    const [searchResult, setSearchResult] = useState(null);
    const [error, setError] = useState(null);
    const [resetMessage, setResetMessage] = useState(null);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openSMSModal, setOpenSMSModal] = useState(false); // State to control SMS modal
    const navigate = useNavigate();

    const handleChange = (e) => {
        setPassportNumber(e.target.value.toUpperCase());
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSearchResult(null);
        setResetMessage(null);

        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/personal-info/search/by/passport/${passportNumber}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (data.success) {
                setSearchResult(data.data);
            } else {
                setError(data.message || "Unknown error occurred");
            }
        } catch (err) {
            setError(err.message || "An error occurred while fetching the data");
        }
    };

    const handleReset = async () => {
        setError(null);
        setResetMessage(null);

        let resetEmail = email;

        if (!resetEmail && searchResult && searchResult.length > 0) {
            resetEmail = searchResult[0].email;
        }

        if (!resetEmail) {
            setError("Please enter your email or perform a search to reset the password.");
            return;
        }

        setLoading(true);

        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/users/password/reset/call-center/${resetEmail}`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (data.success) {
                setResetMessage("Password reset email has been sent successfully.");
            } else {
                setError(data.message || "Unknown error occurred while resetting the password");
            }
        } catch (err) {
            setError(err.message || "An error occurred while resetting the password");
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmReset = () => {
        setOpenModal(false);
        handleReset();
    };

    const openModel = () => {
        setIsOpen(!isOpen);
    };

    const closeModel = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="header header-before"
                 style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                <div style={{display: "flex"}} className="statistics-container">
                    <a href="/admin" target="_blank" style={{textDecoration: 'none'}}>
                        <Button
                            type="button"
                            style={{
                                border: '1px solid white',
                                color: 'white',
                                marginRight: '15px',
                                paddingLeft: '30px',
                                paddingRight: '30px'
                            }}
                        >
                            Admin
                        </Button>
                    </a>
                    {/*<Button*/}
                    {/*    type="button"*/}
                    {/*    style={{border: '1px solid white', color: 'white', marginRight: '15px'}}*/}
                    {/*    onClick={() => setOpenSMSModal(true)}  // Open the SendMessage modal*/}
                    {/*>*/}
                    {/*    Send SMS*/}
                    {/*</Button>*/}
                    <div>
                        <SendMessage/>
                    </div>
                    <Button
                        type="button"
                        style={{border: '1px solid white', color: 'white', marginRight: '15px'}}
                        onClick={openModel}
                    >
                        Send a new link
                    </Button>
                    {isOpen && <LinkModal closeModel={closeModel}/>}
                </div>
            </div>
            <div className="container">
                <form className="passport-search"
                      style={{marginTop: "20px", display: "flex", alignItems: "center", justifyContent: "center"}}
                      onSubmit={handleSubmit}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                name="passport"
                                label="Passport Number"
                                id="passportNumber"
                                inputProps={{ maxLength: 9 }}
                                value={passportNumber}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                    if (e.charCode === 32) {
                                        e.preventDefault();
                                    }
                                }}
                                placeholder="AC1234567"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ height: "100%", width: "150px" }}
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="button"
                                variant="outlined"
                                style={{ height: "100%" }}
                                onClick={() => setOpenModal(true)}
                            >
                                Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                {error && <div style={{ color: 'red', marginTop: '20px' }}>{error}</div>}
                {resetMessage && <div style={{ color: 'green', marginTop: '20px', textAlign: "center", fontSize: "20px" }}>{resetMessage}</div>}
                {searchResult && (
                    <Grid container spacing={2} style={{ marginTop: '10px' }} justifyContent="center">
                        {searchResult.length > 0 ? (
                            searchResult.map((applicant, index) => (
                                <Grid item key={index} xs={12} md={12}>
                                    <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
                                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                                            <Typography variant="body1"><strong>First Name:</strong> {applicant.firstName}</Typography>
                                            <Typography variant="body1"><strong>Last Name:</strong> {applicant.lastName}</Typography>
                                            <Typography variant="body1"><strong>Email:</strong> {applicant.email}</Typography>
                                            <Typography variant="body1"><strong>Passport:</strong> {applicant.passport}</Typography>
                                            <Typography variant="body1"><strong>Phone:</strong> {applicant.phone}</Typography>
                                            <Typography variant="body1"><strong>Operator ID:</strong> {applicant.callCenterRefId}</Typography>
                                            <Typography variant="body1"><strong>Status:</strong> {applicant.applicationStatus}</Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="body1">No results found.</Typography>
                        )}
                    </Grid>
                )}
                <CallCenterDataTable />
            </div>
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
                <DialogTitle>Reset Password Confirmation</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to reset the password and send an email?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmReset} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CallOperators;
