import React, { useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CallCenterDataTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filter, setFilter] = useState('All');
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            const token = localStorage.getItem('userToken');
            const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/personal-info/all/by/call-center-ref-id`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const responseData = await response.json();

                if (responseData.success) {
                    const reversedData = responseData.data.reverse();
                    setData(reversedData);
                } else {
                    setError(responseData.message || 'Unknown error occurred');
                }
            } catch (error) {
                setError(error.message || 'An error occurred while fetching the data');
            }

            setLoading(false);
        };

        fetchData();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMenuClick = (event) => {
        if (anchorEl) {
            handleCloseMenu();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeFilterStatus = (status) => {
        setFilter(status);
        handleCloseMenu();
    };

    const isMenuOpen = Boolean(anchorEl);

    const filteredData = data.filter(item => filter === 'All' || item.applicationStatus === filter);

    const getStatusColor = (status) => {
        switch (status) {
            case 'PENDING':
                return { backgroundColor: "#fdf501", color: "#000000", borderRadius: "8px", textAlign: "center", padding: "2px 5px", width: "115px" };
            case 'APPROVED':
                return { backgroundColor: "#17FA1F", color: "#000000", borderRadius: "8px", padding: "2px 5px", textAlign: "center", width: "115px"};
            case 'REJECTED':
                return { backgroundColor: "#f31515", color: "#000000", borderRadius: "8px", padding: "2px 5px", textAlign: "center", width: "115px" };
            case 'SUBMITTED':
                return { backgroundColor: "#FFF", color: "#000000", border: "1px solid #32383e", borderRadius: "8px", padding: "2px 5px", textAlign: "center", width: "115px" };
            default:
                return { backgroundColor: "#ffffff", color: "#000000", border: "1px solid #dddddd", borderRadius: "8px", padding: "2px 5px", textAlign: "center", width: "115px" };
        }
    };

    return (
        <TableContainer component={Paper} style={{ marginTop: '40px' }}>
            <Table aria-label="call-center-data-table">
                <TableHead>
                    <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Passport</TableCell>
                        <TableCell onClick={handleMenuClick} style={{ cursor: 'pointer' }}>
                            Application Status
                            <IconButton size="small">
                                {isMenuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={isMenuOpen}
                                onClose={handleCloseMenu}
                            >
                                <MenuItem onClick={() => handleChangeFilterStatus('All')}>All</MenuItem>
                                <MenuItem onClick={() => handleChangeFilterStatus('SUBMITTED')}>Submitted</MenuItem>
                                <MenuItem onClick={() => handleChangeFilterStatus('APPROVED')}>Approved</MenuItem>
                                <MenuItem onClick={() => handleChangeFilterStatus('PENDING')}>Pending</MenuItem>
                                <MenuItem onClick={() => handleChangeFilterStatus('REJECTED')}>Rejected</MenuItem>
                            </Menu>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                                <Typography variant="body1">Loading...</Typography>
                            </TableCell>
                        </TableRow>
                    ) : error ? (
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                                <Typography variant="body1" color="error">{error}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : data.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                                <Typography variant="body1">No data available</Typography>
                            </TableCell>
                        </TableRow>
                    ) : filteredData.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={6} align="center">
                                <Typography variant="body1">No data available</Typography>
                            </TableCell>
                        </TableRow>
                    ) : (
                        filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.firstName}</TableCell>
                                    <TableCell>{row.lastName}</TableCell>
                                    <TableCell>{row.phone}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.passport}</TableCell>
                                    <TableCell>
                                        <Typography style={getStatusColor(row.applicationStatus)}>
                                            {row.applicationStatus}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                    )}
                </TableBody>
            </Table>
            <Box display="flex">
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </TableContainer>
    );
};

export default CallCenterDataTable;
